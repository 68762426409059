import React from "react";
/* import styled from "styled-components"; */

/* const Disclaimer = styled.p`
  font-size: 9px;
  color: #666666;
`; */

/* const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`; */

/* const ListItem = styled.li`
  font-size: 12px;
  color: #333;
  display: inline-block;
  margin: 0 16px;
  text-decoration-line: underline;
  &:first-child {
    margin: 0 16px 0 0;
  }
`; */

function Footer() {
  return (
    <div>
      {/* <div>
        <List>
          <ListItem>Terms of use</ListItem>
          <ListItem>Privacy Policy</ListItem>
          <ListItem>About EstCap</ListItem>
          <ListItem style={{ fontWeight: "bold" }}>Register Your Fund</ListItem>
        </List>
      </div>
      <Disclaimer>
        Site Disclaimer :Subscription of funds should be made on the basis of
        the funds’ rules and other fund information, and the latest available
        annual or semi-annual reports. The funds may be profitable or make
        losses. There is no guarantee that investors will get back the amount
        invested in the funds or that it will grow. An investment in the funds
        entails risks, some of which are described in the prospectus of the
        funds. Investors should be aware of the high risks associated with
        investments in emerging markets. Due to the funds investment policy, the
        net asset value of a fund unit may be highly volatile; therefore, an
        investor should have a long term investment perspective.
      </Disclaimer> */}
    </div>
  );
}

export default Footer;
