import React, { useEffect } from "react";
import styled from "styled-components";
import Veriff from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Footer from "../components/Footer";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 32px;
`;

const SubHeader = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 32px;
`;

const Disclaimer = styled.p`
  font-size: 11px;
  color: #666666;
`;

function Verify() {
  useEffect(() => {
    const veriff = Veriff({
      host: "https://stationapi.veriff.com",
      apiKey: process.env.REACT_APP_VERIFF_KEY,
      parentId: "veriff-root",
      onSession(err: any, response: { verification: { url: any } }) {
        createVeriffFrame({
          url: response.verification.url,
          onEvent(msg) {
            switch (msg) {
              case MESSAGES.CANCELED:
                window.location.href = "/";
                break;
              case MESSAGES.FINISHED:
                window.location.href = "verify-success";
                break;
              default:
                break;
            }
          },
        });
      },
    });
    veriff.mount({
      formLabel: {
        givenName: "First name",
        lastName: "Family name",
        vendorData: "Fund name",
      },
      submitBtnText: "Get verified",
      loadingText: "Please wait...",
    });
  }, []);

  return (
    <MainLayout>
      <Heading>Verification</Heading>
      <SubHeader>
        In order to register as an investor, we ask you to verify yourself.
      </SubHeader>
      <div id="veriff-root" />
      <Disclaimer>
        By continuing, you accept that your personal data will be processed by
        EstCap OÜ in line with the{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> and registering as a
        fund investor.
      </Disclaimer>
      <div style={{ marginTop: 100 }}>
        <Footer />
      </div>
    </MainLayout>
  );
}

export default Verify;
