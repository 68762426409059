import React from "react";
import { Route, withRouter, Redirect, useLocation } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = window.localStorage.getItem("bearerToken");
  const location = useLocation();
  let renderRoute;

  if (location.pathname === "/login" || location.pathname === "/register") {
    renderRoute = (props: any) =>
      // eslint-disable-next-line react/jsx-props-no-spreading
      isLoggedIn ? <Redirect to="/dashboard" /> : <Component {...props} />;
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    renderRoute = (props: any) => <Component {...props} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route render={renderRoute} {...rest} />;
};

export default withRouter(PublicRoute);
