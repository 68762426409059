import React from "react";
import { Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "../views/Dashboard";
import Register from "../views/Register";
import Login from "../views/Login";
import Home from "../views/Home";
import Verify from "../views/Verify";
import VerifySuccess from "../views/VerifySucess";
import PrivacyPolicy from "../views/PrivacyPolicy";

const RoutedContent = () => {
  const location = useLocation();
  return (
    <Switch location={location}>
      <PrivateRoute component={Dashboard} path="/dashboard" />
      <PublicRoute component={Register} path="/register" />
      <PublicRoute component={Login} path="/login" />
      <PublicRoute component={PrivacyPolicy} path="/privacy-policy" />
      <PublicRoute component={VerifySuccess} path="/verify-success" />
      <PublicRoute component={Home} path="/home" />
      <PublicRoute component={Verify} path="/" />
    </Switch>
  );
};

export default RoutedContent;
