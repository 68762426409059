import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

API.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (window.localStorage.getItem("bearerToken")) {
      newConfig.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "bearerToken"
      )}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

export default API;
