import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #333;
`;

const FormInput = styled.input`
  display: block;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  min-width: 264px;
  padding: 0 12px;
`;

interface InputProps {
  readonly label: string;
  readonly type: string;
  readonly id: string;
  readonly inputRef: any;
}

function Input({ label, type, inputRef, id }: InputProps) {
  return (
    <>
      <FormLabel htmlFor={id}>
        {label}
        <FormInput type={type} id={id} name={id} ref={inputRef} />
      </FormLabel>
    </>
  );
}

export default Input;

Input.defaultProps = {
  label: "",
  type: "text",
  inputRef: () => null,
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
};
