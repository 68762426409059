import React from "react";
import styled from "styled-components";
import MainLayout from "../components/MainLayout";
import Footer from "../components/Footer";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #000;
`;

const LogOut = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-decoration-line: underline;
  cursor: pointer;
`;

function Dashboard() {
  const logOutUser = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const leftContent = (
    <>
      <Heading>Dashboard</Heading>
      <p>
        Thank you for registering! <br />
        <br />
        We will review your data and get in touch in case additional information
        is required. <br />
        <br />
        You can always contact us by sending us an email at support@estcap.ee
        <br />
        <br />
        <br />
        EstCap Team
      </p>
      <Footer />
    </>
  );

  const rightContent = (
    <>
      <LogOut onClick={logOutUser}>Log out</LogOut>
    </>
  );

  return <MainLayout leftContent={leftContent} rightContent={rightContent} />;
}

export default Dashboard;
