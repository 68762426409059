import React from "react";
import MainLayout from "../components/MainLayout";
import LazyLoad from "../components/LazyLoad";

const PrivacyPolicyDocument = React.lazy(
  () => import("../components/PrivacyPolicyDocument")
);

function PrivacyPolicy() {
  return (
    <MainLayout>
      <LazyLoad component={PrivacyPolicyDocument} />
    </MainLayout>
  );
}

export default PrivacyPolicy;
