import React from "react";
import styled from "styled-components";
import MainLayout from "../components/MainLayout";
import Footer from "../components/Footer";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 32px;
`;

function VerifySuccess() {
  return (
    <MainLayout>
      <Heading>Verification successful!</Heading>
      <div style={{ marginTop: 100 }}>
        <Footer />
      </div>
    </MainLayout>
  );
}

export default VerifySuccess;
