import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Button from "../components/FormComponents/Button";
import Footer from "../components/Footer";

const Heading = styled.h1`
  font-size: 52px;
  font-weight: 700;
  color: #000;
`;

/*
const SignIn = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 162px;
`;
*/

function Home() {
  return (
    <MainLayout>
      <Heading>
        Tailored onboarding and registry services for private funds
      </Heading>
      <Link to="/verify">
        <Button>VERIFY</Button>
      </Link>
      {/* <SignIn>
        Already have an account?{" "}
        <Link to="/sign-in">
          <span style={{ textDecoration: "underline", color: "#000" }}>
            Sign in
          </span>
        </Link>{" "}
        →
      </SignIn> */}
      <div style={{ marginTop: 100 }}>
        <Footer />
      </div>
    </MainLayout>
  );
}

export default Home;
