import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

interface FormButtonProps {
  readonly size: string;
  readonly children: string;
  readonly width: string;
  readonly type: "button" | "submit" | "reset";
  readonly onClick: any;
}

const FormButton = styled.button<FormButtonProps>`
  background: #000000;
  border-radius: 4px;
  padding: ${(props) => (props.size === "small" ? "6px" : "12px")};
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: none;
  min-width: 250px;
  width: ${(props) => props.width};
`;

function Button({ children, size, width, type, onClick }: FormButtonProps) {
  return (
    <FormButton type={type} size={size} width={width} onClick={onClick}>
      {children}
    </FormButton>
  );
}

export default Button;

Button.defaultProps = {
  size: "",
  width: "",
  type: "button",
  onClick: () => null,
};

Button.propTypes = {
  size: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
