import React, { Suspense } from "react";
import Loader from "./Loader";

// @ts-ignore
// eslint-disable-next-line react/prop-types
const LazyLoad = ({ component: Component, ...rest }) => (
  <>
    <Suspense fallback={<Loader />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...rest} />
    </Suspense>
  </>
);

export default LazyLoad;
