import React from "react";
import "../css/loader.scss";

const Loader = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
